<template>
  <div>
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      「プラットフォーム手数料」とは？
    </h5>
    <div class="font-12px text-333 line-height-14">
      <p>
        <span class="fw-700"> プラットフォーム手数料（PF手数料）</span
        >とは、出前館、Uber
        Eats、Wolt、menu他、デリバリーサービス利用手数料です。
      </p>
      <p>
        これらの手数料は一般的に30~35%であることが多い為、月次デリバリー売上の35％をPF手数料として月の概算コストを見積もってください。
      </p>
      <p class="text-center text-8e mb-2">
        <span class="black--text">例）</span>
        デリバリー売上 　 PF手数料率　 　PF手数料
      </p>
      <p class="text-center ml-15">50万円　　x　　35% 　 ＝ 　17.5万円</p>
      <p>
        ※ デリバリー外部サービスを利用されていない方は 0 円のままで構いません。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformFeeModal"
};
</script>
